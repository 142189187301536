<template>
  <div v-if="payment">
    {{paymentStatus}} ({{paymentType}})
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  computed: {
    payment() {
      return this.row.payment
    },
    paymentStatus() {
      return this.$t(`ec.data.payment_status.${this.payment.status}`)
    },
    paymentType() {
      return this.$t(`ec.data.payment_type.${this.payment.type}`)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
